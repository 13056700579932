import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import authentication from './authentication'
import claimsModule from '@/modules/claims/store'
import myAccountModule from './my-account'
import moduleLinks from './module-links'
import linesModule from './lines'
import quoteModule from './quote'
import policiesModule from './policies'
import contactsModule from './contacts'
import documentsModule from './documents'
import rulesModule from './rules'
import integrationsModule from './integrations'
import userManagementModule from './user-management'
import bcClassicModule from './bc-classic'
import {BriteNotesStore} from '@intuitivewebsolutions/britenotes-ui'
import accessModule from './access'
import activityModule from './activity'
import BriteRulesConnectorStore from '@/pages/rules/briterules-connector/store'
import BriteRulesConnector from '@/pages/rules/briterules-connector'
import {getSafeRedirectPath} from '@/utilities/utils'

Vue.use(Vuex)

const store = new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production',
  state: {
    activeModule: '',
    sidebarHeaderTitle: '',
    loading: false,
    sidebarVisible: false,
    subSidebarVisibleMobile: false
  },
  actions: {
    setWindowLocation ({commit}, redirectPath) {
      commit('loading', true)
      window.location = getSafeRedirectPath(redirectPath)
    }
  },
  mutations: {
    loading (state, loading) {
      state.loading = loading
    },
    activeModule (state, activeModule) {
      state.activeModule = activeModule
    },
    sidebarHeaderTitle (state, sidebarHeaderTitle) {
      state.sidebarHeaderTitle = sidebarHeaderTitle
    },
    toggleSidebar (state, type) {
      if ((type && type === 'hide') || state.sidebarVisible) {
        state.sidebarVisible = false
      } else {
        state.sidebarVisible = true
      }
    },
    toggleSubSidebarMobile (state, type) {
      if (type === 'show') {
        state.subSidebarVisibleMobile = true
      } else if (type === 'hide') {
        state.subSidebarVisibleMobile = false
      }
    }
  },
  modules: {
    bcClassic: bcClassicModule,
    authentication: authentication,
    lines: linesModule,
    quote: quoteModule,
    policies: policiesModule,
    contacts: contactsModule,
    documents: documentsModule,
    rules: rulesModule,
    myAccount: myAccountModule,
    claims: claimsModule,
    userManagement: userManagementModule,
    integrations: integrationsModule,
    moduleLinks: moduleLinks,
    notes: BriteNotesStore,
    access: accessModule,
    activity: activityModule,
    [BriteRulesConnector.moduleName]: BriteRulesConnectorStore
  },
  plugins: [
    createPersistedState({
      reducer: (state) => {
        const {
          notes,
          activity,
          access: {evaluations},
          authentication: {isBeingLoggedOut}
        } = state
        if (isBeingLoggedOut) {
          return {}
        }
        return {notes, activity, access: {evaluations}}
      }
    })
  ]
})

export default store
