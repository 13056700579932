export const isPromise = p => Promise.resolve(p) === p

export const enabledModules = (process.env.VUE_APP_ENABLED_MODULES || '').split(' ').filter(moduleName => moduleName)

export const isClaimsModuleEnabled = enabledModules.includes('claims')

/**
 * Initiates a download given the filename, mimetype and content
 * @param {string} filename - name of the file
 * @param {string} mimetype - MIME type
 * @param {string} data - content of the file
 */
export const download = (filename, mimetype, data) => {
  const blob = new Blob([data], { type: mimetype })
  const el = window.document.createElement('a');
  [el.href, el.download] = [window.URL.createObjectURL(blob), filename]
  document.body.appendChild(el)
  el.click()
  document.body.removeChild(el)
}

/**
 * Returns a promise that resolves to the content of the file. Rejected if there's an error reading it.
 * @param {File} file - reference to the file to read
 * @returns {Promise} promise that resolves to the content of the file
 */
export const getFileContent = file => {
  const reader = new FileReader()
  reader.readAsText(file, 'UTF-8')
  return new Promise((resolve, reject) => {
    reader.onload = evt => resolve(evt.target.result)
    reader.onerror = () => {
      reader.abort()
      reject()
    }
  })
}

/**
 * Returns a safe redirect path that points to an http or https protocol where BriteCore app is accessible.
 * @param {string} redirectPath
 * @returns {string}
 */
export const getSafeRedirectPath = redirectPath => {
  try {
    const urlObj = new URL(redirectPath, window.location.href)
    const protocol = urlObj.protocol.toLowerCase()
    if ((protocol !== 'http:') && (protocol !== 'https:')) {
      return '/'
    }
  } catch (err) {
    return '/'
  }
  return redirectPath
}
