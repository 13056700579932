import {_claimActions} from './action-types'
import {_claimMutations} from './mutation-types'
import {claimsApi} from '@/modules/claims/utils/api'
import api from '@/modules/claims/services'
import AttachmentsApi from '@/modules/claims/services/attachments'
import UsersApi from '@/modules/claims/services/users'
import {Message} from '@britecore/bc-design-system'
import {isObject} from 'lodash'
import {
  CLAIM_INCLUDE,
  EXPOSURE_INCLUDE,
  EXPOSURES_INCLUDE,
  FNOL_INCLUDE, PRODUCT_INCLUDE
} from '@/modules/claims/services/constants'
import ContactsApi from '@/modules/claims/services/contacts'
import services from '@/api/services'

const usersApi = new UsersApi()
const attachmentsApi = new AttachmentsApi()
const contactsApi = new ContactsApi()

export default {
  async [_claimActions.GET_CLAIM] ({dispatch}, {id, params = {}}) {
    if (!params.include) {
      params.include = CLAIM_INCLUDE
    }
    const response = await api.claim.get({id}, params)
    dispatch(_claimActions.SET_CLAIM, response.data)
    return response
  },
  async [_claimActions.GET_CLAIM_BY_NUMBER] ({dispatch}, {number, include = CLAIM_INCLUDE}) {
    // search for non-draft status claim
    const {data} = await api.claim.list({filter: {numbers: number}, include})

    if (data.length > 0) {
      const _data = data[0]
      dispatch(_claimActions.SET_CLAIM, _data)
      return {data: _data}
    }

    const message = `Claim ${number} not found.`
    Message.error(message)
    // To ensure error is raised consistently, we rethrow an error.
    throw message
  },
  async [_claimActions.UPDATE_CLAIM_TAGS] ({dispatch}, {id, tags}) {
    let updateTags = []
    for (const tag of tags) {
      updateTags.push({type: 'tag', id: tag})
    }
    await services.claims.one('claim', id).relationships('tags').patch(updateTags)
    dispatch(_claimActions.GET_CLAIM_TAGS, id)
  },
  async [_claimActions.GET_CLAIM_TAGS] ({commit}, id) {
    const {data} = await services.claims.one('claim', id).all('tag').get()
    commit(_claimMutations.SET_CLAIM_TAGS, data)
  },
  [_claimActions.SET_CLAIM] ({commit}, claim) {
    if (claim) {
      commit(_claimMutations.SET_CLAIM, claim)
    }
    if (isObject(claim) && isObject(claim.product)) {
      commit(_claimMutations.SET_PRODUCT, claim.product)
    }
  },
  async [_claimActions.UPDATE_CLAIM] ({dispatch}, {id, data, params = {}}) {
    if (!params.include) {
      params.include = CLAIM_INCLUDE
    }
    const response = await api.claim.update({id, data}, params)
    dispatch(_claimActions.SET_CLAIM, response.data)
    return response
  },
  async [_claimActions.CREATE_FNOL] ({dispatch}, {data, params = {}}) {
    if (!params.include) {
      params.include = FNOL_INCLUDE
    }
    let response
    // Create fnol
    response = await api.claim.create({data}, params)
    // Update store
    dispatch(_claimActions.SET_CLAIM, response.data)
    return response
  },
  async [_claimActions.GET_FNOL_BY_NUMBER] ({dispatch}, {number, include = FNOL_INCLUDE}) {
    // search for draft status claim
    let response = await api.claim.list({filter: {number, status: 'draft'}, include})

    // search for non-draft status claim
    if (response.data.length === 0) {
      response = await api.claim.list({filter: {number}, include})
    }

    // Put the claim in the store and return the response
    if (response.data.length > 0) {
      const data = response.data[0]
      dispatch(_claimActions.SET_CLAIM, data)
      return {data}
    }
    const message = `FNOL ${number} not found.`
    Message.error(message)
    throw message
  },
  async [_claimActions.GET_PRODUCT_BY_ID] ({commit}, {id, include = PRODUCT_INCLUDE}) {
    const {data} = await services.claims.one('product', id).get({
      include: include
    })
    commit(_claimMutations.SET_PRODUCT, data)
    return data
  },
  async [_claimActions.UPDATE_FNOL] ({dispatch}, {id, data, params = {}}) {
    if (!params.include) {
      params.include = FNOL_INCLUDE
    }
    const response = await api.claim.update({id, data}, params)
    dispatch(_claimActions.SET_CLAIM, response.data)
    return response
  },
  async [_claimActions.DELETE_EXPOSURE] ({commit}, {id}) {
    await api.exposure.delete({id})
    commit(_claimMutations.REMOVE_EXPOSURE, id)
  },
  async [_claimActions.GET_EXPOSURE_BY_NUMBER] ({commit}, {claimId, number, include = EXPOSURE_INCLUDE}) {
    const {data} = await api.exposure.list(
      {filter: {claim: claimId, number: number}, include}
    )

    if (data) {
      const exposure = data.shift()
      commit(_claimMutations.SET_EXPOSURE, exposure)
      return {data: exposure}
    }

    const message = `Exposure ${number} not found.`
    Message.error(message)
    // To ensure error is raised consistently, we rethrow an error.
    throw message
  },
  async [_claimActions.GET_EXPOSURES_BY_CLAIM_NUMBER] ({commit}, {number, include = EXPOSURES_INCLUDE}) {
    const {data} = await api.exposure.listAll({filter: {claim_number: number}, include})

    commit(_claimMutations.SET_EXPOSURES, data)
    return {data}
  },
  [_claimActions.GET_PAYMENTS] ({state, commit}, params) {
    const config = {
      params: params
    }
    return claimsApi.get('payments/', config).then(response => {
      return response
    })
  },
  async [_claimActions.UPDATE_LOSS_LOCATION] ({commit, state}, {id, data}) {
    const {data: _data} = await api.location.update({id, data})

    commit(_claimMutations.SET_CLAIM_LOSS_LOCATION, _data)
    return {data: _data}
  },
  async [_claimActions.CREATE_LOSS_LOCATION] ({commit, state}, {data}) {
    const {data: _data} = await api.location.create({data})

    commit(_claimMutations.SET_CLAIM_LOSS_LOCATION, _data)
    return {data: _data}
  },
  async [_claimActions.UPDATE_EXPOSURE] ({commit}, {id, data, params = {}}) {
    if (!params.include) {
      params.include = EXPOSURE_INCLUDE
    }
    const {data: _data} = await api.exposure.update({id, data}, params)

    commit(_claimMutations.SET_EXPOSURE, _data)
    return {data: _data}
  },
  async [_claimActions.UPDATE_EXPOSURE_TAGS] ({dispatch}, {id, tags}) {
    let updateTags = []
    for (const tag of tags) {
      updateTags.push({type: 'tag', id: tag})
    }
    await services.claims.one('exposure', id).relationships('tags').patch(updateTags)
    dispatch(_claimActions.GET_EXPOSURE_TAGS, id)
  },
  async [_claimActions.GET_EXPOSURE_TAGS] ({commit}, id) {
    const {data} = await services.claims.one('exposure', id).all('tag').get()
    commit(_claimMutations.SET_EXPOSURE_TAGS, data)
  },
  async [_claimActions.GET_PARTIES] ({state, commit}) {
    try {
      const {data} = await api.party.listAll({
        include: 'contact',
        filter: {claim: state.claim.id},
        sort: 'name'
      })
      commit(_claimMutations.SET_PARTIES, data)
      return {data}
    } catch (_) {
      commit(_claimMutations.SET_PARTIES, [])
      return {data: []}
    }
  },
  async [_claimActions.GET_ATTACHMENTS] ({state, commit}) {
    try {
      const response = await attachmentsApi.getAttachments(state.claim.id, {
        params: {
          list_view: true,
          reference_id: state.claim.id
        }
      })
      if (response) {
        commit(_claimMutations.SET_ATTACHMENTS, response.attachments)
      }
    } catch (_) {
      const message = 'Failed to get attachments.'
      Message.error(message)
    }
  },
  async [_claimActions.GET_CURRENT_USER] ({rootState, commit}) {
    const user = await usersApi.getUser(rootState.authentication.currentUser.username)
    if (user) {
      commit(_claimMutations.SET_CURRENT_USER, user)
    }
  },
  async [_claimActions.GET_GENDERS] ({commit}) {
    const genders = await contactsApi.getGenders()
    if (genders) {
      commit(_claimMutations.SET_GENDERS, genders)
    }
  },
  async [_claimActions.GET_ADDRESS_TYPES] ({commit}) {
    const addressTypes = await contactsApi.getAddressTypes()
    if (addressTypes) {
      commit(_claimMutations.SET_ADDRESS_TYPES, addressTypes)
    }
  },
  async [_claimActions.GET_ALLOW_DUPLICATE_SSN] ({commit}) {
    const allowDuplicateSSN = await contactsApi.allowDuplicateSSN()
    if (allowDuplicateSSN !== undefined) {
      commit(_claimMutations.SET_ALLOW_DUPLICATE_SSN, allowDuplicateSSN)
    }
  }
}
